<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7C0 3.129 3.129 0 7 0C10.871 0 14 3.129 14 7C14 10.871 10.871 14 7 14C3.129 14 0 10.871 0 7ZM9.02348 10.01C9.29648 10.283 9.73748 10.283 10.0105 10.01C10.2765 9.73698 10.2765 9.28898 10.0105 9.02298L7.98748 6.99998L10.0105 4.97698C10.2835 4.70398 10.2835 4.26298 10.0105 3.98998C9.73748 3.71698 9.29648 3.71698 9.02348 3.98998L7.00048 6.01298L4.97748 3.98998C4.70448 3.71698 4.26348 3.71698 3.99048 3.98998C3.85941 4.12076 3.78574 4.29832 3.78574 4.48348C3.78574 4.66864 3.85941 4.8462 3.99048 4.97698L6.01348 6.99998L3.99048 9.02298C3.85941 9.15376 3.78574 9.33132 3.78574 9.51648C3.78574 9.70164 3.85941 9.8792 3.99048 10.01C4.26348 10.283 4.70448 10.283 4.97748 10.01L7.00048 7.98698L9.02348 10.01Z" fill="#001B24" fill-opacity="0.4"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconEwClose = Vue.extend({
  name: 'icon-ew-close',
});
export default IconEwClose;
</script>
