


import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import Avatar from '@/_components/avatar/avatar.vue';
import { TNotification } from '@/_modules/promo/types/notification.type';
import { NotificationType } from '@/_modules/promo/types/notification-type.enum';
import { mapGetters } from 'vuex';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import { TMeeting } from '@/_types/meeting/meeting.type';
import Person from '@/_modules/contacts/components/person/person.vue';
import OnlineIndicator from '@/_ui/online-indicator/online-indicator.vue';
import IconEwClose from '@/_modules/icons/components/icon-ew-close.vue';
import { Action } from 'vuex-class';

const NOTIFICATION_SOUND = require('@/assets/sounds/intuition.ogg');

@Component({
  components: {
    Avatar,
    Person,
    OnlineIndicator,
    IconEwClose
  },
  computed: {
    ...mapGetters({
      promoPageByExternalId: 'promoStore/promoPageByExternalId'
    }),
  }
})
export default class PromoNotification extends Vue {

  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName: string }) => void;

  public readonly promoPageByExternalId: (externalId: string) => TPromoPage;
  public readonly NotificationType: typeof NotificationType = NotificationType;

  @Prop({ type: Object })
  public readonly notification: TNotification;

  @Prop({ type: Boolean, default: false })
  public readonly playSound: boolean;

  public mounted(): void {
    this.dispatchDataRequests();
    if (this.playSound) {
      const audio = new Audio(NOTIFICATION_SOUND);
      try { audio.play(); } catch { /* ignore */ }
    }
  }

  public get timeString(): string {
    return (this.notification && this.notification.time && this.notification.time.format('lll')) || '';
  }

  public get isNotificationNotAboutPastEvent(): boolean {
    return new Date().getTime() <= +this.notification.time;
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get meetingCommunicationType(): string {
    const meeting: TMeeting = (this.notification && this.notification.meeting) || null;
    if (meeting) {
      return ((meeting.communication_type || '').toLowerCase()) === 'offline' ? 'offline' : 'online';
    }
    return '';
  }

  public get contactGeoInfo(): string {
    if (!this.notification || !this.notification.contact) {
      return '';
    }
    const result = [this.notification.contact.city, this.notification.contact.country].filter(x => x);
    return result.join(', ');
  }

  public get contactFullName(): string {
    if (!this.notification || !this.notification.contact) {
      return '';
    }
    if (this.notification.contact.fullName) {
      return this.notification.contact.fullName;
    }
    return [this.notification.contact.name, this.notification.contact.surname].filter(x => x).join(' ').trim();
  }

  public get companyAvatarSrc(): string {
    if (!this.notification || !this.notification.external_id) {
      return '';
    }
    const promopage: TPromoPage = this.promoPageByExternalId(this.notification.external_id);
    return (promopage && promopage.logo_url) || '';
  }

  public get companyTitle(): string {
    if (!this.notification || !this.notification.external_id) {
      return '';
    }
    const promopage: TPromoPage = this.promoPageByExternalId(this.notification.external_id);
    return (promopage && promopage.title) || '';
  }

  public get conferenceProgramTitle(): string {
    if (!this.notification || !this.notification.conferenceProgram || !this.notification.conferenceProgram.title) {
      return '';
    }
    return this.notification.conferenceProgram.title;
  }

  public get conferenceProgramUrlFormattedDate(): string {
    if (!this.notification || !this.notification.conferenceProgram || !this.notification.conferenceProgram.date_start) {
      return '';
    }
    return this.$moment(this.notification.conferenceProgram.date_start).format(DateTimeFormat.DATE_TINY);
  }

  public get meetingTimeRangeFormatted(): string {
    if (!this.notification.meeting || this.notification.type !== NotificationType.CONTACT_MEETING_REQUEST) {
      return '';
    }

    const meeting: TMeeting = this.notification.meeting;

    if (meeting && meeting.date_start && meeting.date_end) {
      const dm: string = this.$moment(meeting.date_start).format('D MMMM');
      const d: string = this.$moment(meeting.date_start).format('dddd');
      const startTime: string = this.$moment(meeting.date_start).format('HH:mm');
      return `${startTime}, ${dm}, ${d}`;
    }

    return '';
  }

  public get title(): TranslateResult {
    switch (this.notification.type) {
      case NotificationType.CONTACT_MEETING_REQUEST:
        return this.$t('promo.notifications.new_meeting');

      case NotificationType.CONTACT_MEETING_REMINDER:
        return this.$t('promo.notifications.meetingReminder');

      case NotificationType.CONTACT_MESSAGE:
        return this.$t('promo.notifications.newMessage');

      case NotificationType.MEETING_IS_CONFIRMED:
        return this.$t('promoNotificationMeetingConfirmed.title');

    }

    return '';
  }

  public get date(): TranslateResult {
    switch (this.notification.type) {
      case NotificationType.CONTACT_MEETING_REQUEST:
        return this.meetingTimeRangeFormatted;

      case NotificationType.CONTACT_MEETING_REMINDER:
        return '';

      case NotificationType.CONTACT_MESSAGE:
        return '';

      case NotificationType.MEETING_IS_CONFIRMED:
        return '';

    }

    return '';
  }

  public onClick(): void {
    if (!this.notification) {
      return;
    }
    this.$store.dispatch('notificationsStore/popupRemove', this.notification.id);
    if (this.notification.type === NotificationType.CONTACT_MEETING_REQUEST) {
      try {
        this.$router.push({ name: 'promo-page-calendar' }).catch(() => { /* ignore */ });
      } catch { /* ignore */ }
    } else if (this.notification.type === NotificationType.CONTACT_MESSAGE) {
      // this.$store.dispatch('sideBarRightStore/setActiveTab', TabNames.CORRESPONDENCE);
      // this.$store.dispatch('sideBarRightStore/open');
      // this.$store.dispatch('sideBarRightStore/openMessageByContactId', this.notification.contact.id);

      this.openContactCard({
        contactId: this.notification.contact.id,
        startupTabName: 'messages',
      });
    } else if (this.notification.type === NotificationType.NEW_COMPANY_BROADCAST_STARTED) {
      try {
        this.$router.push({
          name: 'promo-page-events-company',
          params: {
            external_id: this.notification.external_id
          }
        }).catch(() => { /* ignore */ });
      } catch { /* ignore */ }
    } else if (this.notification.type === NotificationType.CONFERENCE_PROGRAM_STARTED) {
      try {
        this.$router.push({
          name: 'promo-program-date-program',
          params: {
            date: this.conferenceProgramUrlFormattedDate,
            programId: this.notification.entityId.toFixed(0)
          }
        }).catch(() => { /* ignore */ });
      } catch { /* ignore */ }
    } else if (this.notification.type === NotificationType.MEETING_IS_CONFIRMED) {
      try {
        this.$router.push({ name: 'promo-page-calendar' }).catch(() => { /* ignore */ });
      } catch { /* ignore */ }
    }
  }

  public onButtonCloseClick(): void {
    this.$store.dispatch('notificationsStore/remove', {
      eventId: this.eventId,
      id: this.notification.id,
      notificationId: this.notification.notificationId
    });
  }

  private dispatchDataRequests(): void {
    if (this.notification.type === NotificationType.NEW_COMPANY_BROADCAST_STARTED) {
      if (!this.notification.external_id) {
        return;
      }
      if (!this.promoPageByExternalId(this.notification.external_id)) {
        // AW-1769 — promopages list is not guaranteed to be filled on all pages of the website.
        // We have to request the list to display nice notifications.
        this.$store.dispatch('promoStore/promoPageListAll', { event_id: this.eventId });
      }
    }
  }
}
